import React, { useEffect, useRef, useContext, useState } from 'react';
import L, { map } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GetAnalysis } from './Api.js';
import { ClientContext } from './contexts/ClientContext.js';
//import { lookForLanduse } from './LookForLanduseOSM.js' -- not working

import { convertCoordinates } from './ConvertCoords.js';

import AnalyzePackage from './AnalyzePackageSuggester.js';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder';

import { GridLoader } from 'react-spinners';


const DefaultIcon = L.icon({
    iconUrl: '/marker-icon.png',
    iconRetinaUrl: '/marker-icon-2x.png',
    shadowUrl: '/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const CENTER = [59.3189, 18.0927]; //Starting window Defaults to STHLM

// The distance 1000m is the longest radius that any client is checking for Sweportal API
const DEFAULT_RADIUS = 1000;

const MapWidget = ({ }) => {
    const zoom = 10;
    const mapRef = useRef(null); // Hold the current map
    const markerRef = useRef(null);
    const [analysisResult, setAnalysisResult] = useState([]);
    const [markerPosition, setMarkerPosition] = useState(null);

    const [buttonState, setButtonState] = useState('inactive'); // states: 'inactive', 'active', 'sent'
    const [showAnalysis, setShowAnalysis] = useState(false);

    const [loader, setLoader] = useState(false);

    /**
     * mapReady keeps track if the map is loaded fully
     */
    const [mapReady, setMapReady] = useState(false);

    /**
     * StartPoint to place map upon load
     */
    const { startPoint, origin, key } = useContext(ClientContext);

    useEffect(() => {
        if (startPoint && mapRef.current && mapReady) {
            try {
                mapRef.current.panTo([startPoint.lat, startPoint.lng]);
                if (startPoint.center != null && startPoint.center !== undefined) {

                    placeMarker(startPoint.center);
                }

            } catch (err) {
                console.error(err);
            }

        }
    }, [startPoint, mapReady]);

    useEffect(() => {
        if (markerPosition) {
            loadAnalyze();
        }
    }, [markerPosition]);


    /**
     * useEffect that starts a listener for Iframes postmessage incase a user is iframing our App
     */
    useEffect(() => {
        const receiveMessage = (event) => {
            if (event.data == undefined) {
                return
            }


            if (event.data !== undefined && event.data.type === "search") {
                searchAddress(event.data.address, event.origin);
            }

        }

        window.addEventListener('message', receiveMessage);

        return () => {
            window.removeEventListener('message', receiveMessage);
        }
    }, []);



    // A function to add the fetched entities to the map
    const renderOsmOnMap = (data, entity) => {
        // Extract all ways from the data

        const ways = data.filter(item => item.type === 'way');

        // Convert node ids in ways to their actual lat-lon coordinates
        ways.forEach(way => {
            way.nodes = way.nodes.map(nodeId => {
                const node = data.find(item => item.type === 'node' && item.id === nodeId);
                return node ? [node.lat, node.lon] : null;
            }).filter(Boolean); // Filter out any null values
        });

        // Render these ways on the map
        ways.forEach(way => {
            // Check if the way is a polygon or a polyline

            if (way.nodes[0].toString() === way.nodes[way.nodes.length - 1].toString()) {
                // It's a polygon
                L.polygon(way.nodes, { color: entity.color, fillOpacity: 0.2 })
                    .bindTooltip(`${entity.description}`, { permanent: true, direction: 'top' })
                    .addTo(mapRef.current);
            } else {
                // It's a polyline
                L.polyline(way.nodes, { color: entity.color, fillOpacity: 0.2 })
                    .bindTooltip(`${entity.description}`, { permanent: true, direction: 'center' })
                    .addTo(mapRef.current);
            }
        });

        // Extract all node IDs that are part of a way
        const nodesUsedInWays = ways.flatMap(way => way.nodes.map(coords => coords.toString()));

        // Render standalone nodes
        const standaloneNodes = data.filter(item => item.type === 'node' && !nodesUsedInWays.includes([item.lat, item.lon].toString()));

        standaloneNodes.forEach(node => {
            L.circleMarker([node.lat, node.lon])
                .bindTooltip(`${entity.description}`, { permanent: true })
                .openTooltip()
                .addTo(mapRef.current);
        });
    };

    function zoomToAllFeatures(map) {
        // Create an empty bounds object
        let bounds = new L.LatLngBounds();

        // Loop over each layer in the map
        map.eachLayer(layer => {
            // Check if the layer has a getLatLng method (for markers)
            if (layer.getLatLng) {
                bounds.extend(layer.getLatLng());
            }
            // Check if the layer has a getBounds method (for polylines, polygons, etc.)
            else if (layer.getBounds) {
                bounds.extend(layer.getBounds());
            }
        });

        // If the bounds are valid (i.e., they have been extended at least once), fit the map to them
        if (bounds.isValid()) {
            map.fitBounds(bounds, {
                paddingBottomRight: [50, 200]
            }
            );
        }

    }

    function clearAllLayersExceptMarkers(map) {
        map.eachLayer(function (layer) {
            if (!(layer instanceof L.TileLayer) && !(layer instanceof L.Marker)) {
                map.removeLayer(layer);
            }
        });
    }

    function renderSweportalOnMap(ent, geom) {
        let entity = ent
        let description = entity.description
        L.circleMarker([geom[1], geom[0]], { color: entity.color, fillOpacity: 0.2 })
            .bindTooltip(`${description}`, { permanent: true })
            .openTooltip()
            .addTo(mapRef.current);

    }
    function renderLSTOnMap(ent, geom) {
        let entity = ent
        let description = entity.type + " Riskklass: " + entity.riskklass
        L.circleMarker([geom[1], geom[0]], { color: entity.color, fillOpacity: 0.2 })
            .bindTooltip(`${description}`, { permanent: true })
            .openTooltip()
            .addTo(mapRef.current);

    }

    /**
     * render result will loop through the returned geoms and render them on the map
     * @param {} result 
     */
    const renderResult = async (result) => {
        for (let res of result) {
            // if geom is empty, skip it
            if (res.geom === undefined || res.geom.length == 0) {
                continue
            }
            // Dont draw if render == 0 
            if (res.entity.render === 0) {
                continue;
            }
            if (res.entity.sourceapi === "OSM") {
                renderOsmOnMap(res.geom, res.entity)
            }
            if (res.entity.sourceapi === "SWEPORTAL") {
                const geom = convertCoordinates(3857, 4326, res.geom.coordinates)
                renderSweportalOnMap(res.entity, geom);
            }
            if (res.entity.sourceapi === "LST") {
                renderLSTOnMap(res.entity, res.geom)
            }
        }
    }

    /**
     * loadAnalyze is used to fetch an anlayzis package for a certain gps coordinate
     */
    const loadAnalyze = async () => {

        setLoader(true);
        setShowAnalysis(false);
        setButtonState('sent');
        clearAllLayersExceptMarkers(mapRef.current);
        let { lat, lng } = markerPosition;

        if (!lat || !lng) {
            return;
        }
        try {
            const response = await GetAnalysis(lat, lng, DEFAULT_RADIUS, origin, key);
            if (response) {
                // Now handle the analyze result
                handleAnalyzeResult(response);
            };
        } catch (error) {
            console.error("Error fetching data:", error);
        }

        zoomToAllFeatures(mapRef.current);
        setLoader(false);
        setButtonState('inactive');
    };

    /**
     * handleAnalyzeResult will select the appropriate visualizer handler to use
     */
    const handleAnalyzeResult = async (analyseResult) => {
        if (analyseResult.analysis && analyseResult.visualizer && analyseResult.analysis.length > 0) {
            analyseResult.visualizer.forEach((viz) => {

                switch (viz) {
                    case "event":
                        handleEventVisualization(analyseResult.analysis, analyseResult.callbackURL);
                        break;
                    case "show":
                        // The response will be a list of entity types so Render them on the map
                        renderResult(analyseResult.data);
                        setAnalysisResult(analyseResult.analysis);
                        setShowAnalysis(true);
                        break;
                }
            })
        }
    }

    /**
     * handleEventVisualization is used to perform event pushes if the client is configured to use events
     * @param analyseResult should be the array of analyze packages
     * @param callbackURL is the post message url to target
     */
    const handleEventVisualization = async (analyzeResult, callbackURL) => {

        let { lat, lng } = markerPosition;

        try {
            // Call getGPSLocationInformation asynchronously
            const addressObject = await getGPSLocationInformation(lat, lng);

            const payload = {
                address: addressObject,
                analyse_packages: analyzeResult
            };

            window.parent.postMessage(payload, callbackURL);
        } catch (error) {
            console.error('Error fetching address information:', error);
        }
    }

    /**
     * onUserLocationClick will fetch the userlocation and place marker
     */
    const onUserLocationClick = async () => {
        mapRef.current.locate({ setView: true, maxZoom: 16 });
        function onLocationFound(e) {
            placeMarker(e.latlng)
        }
        mapRef.current.on('locationfound', onLocationFound);

        function onLocationError(e) {
            askForLocationPermission();
            placeMarker(e.latlng)
        }
        mapRef.current.on('locationerror', onLocationError);


    };

    const askForLocationPermission = () => {
        if ('geolocation' in navigator) {
            navigator.permissions
                .query({ name: 'geolocation' })
                .then((permissionStatus) => {
                    if (permissionStatus.state === 'prompt') {
                        // Permission not granted, ask the user
                        navigator.geolocation.getCurrentPosition(
                            () => {
                                // User granted permission, location will be retrieved in the success callback
                            },
                            (error) => {
                                console.error('Error getting location:', error);
                            }
                        );
                    } else {
                        // Permission denied or other state
                        console.warn('Location permission denied.');
                    }
                })
                .catch((error) => {
                    console.error('Error checking location permission:', error);
                });
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    /**
     * placeMarker resets the map and shows a new marker
     * it also resets any analysis shown etc
     * @param {*} pos 
     */
    const placeMarker = (pos) => {
        if (pos) {
            if (markerRef.current) {
                markerRef.current.remove();
            }
            markerRef.current = L.marker(pos)
                .bindTooltip("Klicka i kartan om du vill<br> justera positionen")
                .openTooltip()
                .addTo(mapRef.current);
            setShowAnalysis(false);
            setAnalysisResult([]);
            setMarkerPosition(pos)
            setButtonState('active');

        } else {
            alert("Hittar inte din plats. Det kan bero på att du ej godkänt platsbehörighet i din webbläsare")
        }
    };
    /**
     * getGPSLocationInformation retrieves address information for a given latlng asynchronously
     * @param {*} lat Latitude
     * @param {*} lng Longitude
     * @returns Promise<AddressObject> Promise that resolves to an address object
     */
    const getGPSLocationInformation = async (lat, lng) => {
        return new Promise((resolve, reject) => {
            const geocoder = L.Control.Geocoder.nominatim();

            // Perform reverse geocoding using latlng coordinates
            geocoder.reverse({ lat: lat, lng: lng }, mapRef.current.options.crs.scale(mapRef.current.getZoom()), (results) => {
                if (results.length > 0) {
                    const firstResult = results[0]; // Take the first result

                    // Extract address components
                    const addressObject = {
                        city: firstResult.properties.address.city || '',
                        municipality: firstResult.properties.address.municipality || '',
                        county: firstResult.properties.address.county || '',
                        postalCode: firstResult.properties.address.postcode || '',
                        country: firstResult.properties.address.country || ''
                    };

                    resolve(addressObject);
                } else {
                    reject(new Error('No address found for the coordinates:', lat, lng));
                }
            });
        });
    };

    useEffect(() => {

        var osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        })

        var satellite = L.tileLayer('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            attribution: '&copy; <a href="https://www.google.com/maps">GoogleMaps</a> contributors',
        })

        var hybrid = L.tileLayer('http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}', {
            attribution: '&copy; <a href="https://www.google.com/maps">GoogleMaps</a> contributors',
        })

        var baseMaps = {
            "OpenStreetMap": osm,
            "Satellite": satellite,
            "Hybrid": hybrid
        };

        const map = L.map('map', {
            layers: [osm]
        }).setView(CENTER, zoom); //Set default starting window in Stockholm
        mapRef.current = map; // Store the map instance in the ref

        document.getElementById('map').style.cursor = 'pointer'; // Mouse symbol clicker
        L.control.layers(baseMaps, null, {
            position: 'topleft'
        }).addTo(map);
        L.Control.geocoder({ // Controls the search function
            defaultMarkGeocode: false
        })
            .on('markgeocode', function (e) {
                // TODO: How to search this programatically
                var center = e.geocode.center;
                var bbox = e.geocode.bbox;
                placeMarker(center)
                mapRef.current.fitBounds(bbox, {
                    paddingBottomRight: [100, 100], // CHANGE TO ZOOM LEVELS
                    paddingTopLeft: [100, 100]
                });
            })
            .addTo(map);

        // Click marker event listener
        const onMapClick = (e) => {
            setShowAnalysis(false);
            // If a marker already exists, remove it
            if (markerRef.current) {
                markerRef.current.remove();
            }

            // Add a new marker to the map at the clicked location
            markerRef.current = L.marker(e.latlng)
                .bindTooltip("Klicka i kartan om du vill<br> justera positionen")
                .openTooltip()
                .addTo(map);
            setMarkerPosition(e.latlng)
            setButtonState('active');

        };
        // Attach click event listener
        map.on('click', onMapClick);
        setMapReady(true);

        return () => {
            map.remove();
            map.off('click', onMapClick);  // Cleanup the event listener
        };
    }, []);


    /**
     * searchAddress will search for an address and get the geo data for it
     * @param {*} address 
     * @param {string} origin - used when iframing
     */
    const searchAddress = (address, origin) => {
        const geocoder = L.Control.Geocoder.nominatim();

        geocoder.geocode(address, (results) => {
            if (results.length > 0) {
                const result = results[0]; // Take the first result
                const { lat, lng } = result.center;

                placeMarker({ lat: lat, lng: lng }, origin);
            } else {
                console.error('No results found');
            }
        });
    };


    return (
        <div className="map-container">
            {loader && (
                <div className="loader-container">
                    <GridLoader size={30} speedMultiplier={0.8} color="#EBCD26" />
                </div>
            )}
            <div id="map"></div>

            {/* <button className='user-location-button' onClick={onUserLocationClick}><img src="user_position_icon.png" className="user_position_img" alt="user location button" title='Hämta min postition' /></button> */}
            {showAnalysis && analysisResult.length > 0 && (
                <AnalyzePackage analysisResult={analysisResult} />
            )}
            <a href="https://swescan.se/" target="_blank" rel="noopener noreferrer">
                <div className="powered-by-container" >Powered by: <img src="SWESCAN_LOGO_SMALL.png" alt="Powered by swescan" className="poweredBy-image" /></div>
            </a>
        </div>
    );


};


export default MapWidget;
